<template>
    <div>
        <table-config-users/>
    </div>
</template>

<script>
import TableConfigUsers from '@/components/config/users/table.vue'
export default {
  components: { TableConfigUsers },
    data () {
        return {
           
        }
    }
    
}
</script>